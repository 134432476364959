<template>
  <div class="content-holder">
    <section class="content" id="content">
      <div class="inner">
        <!-- If page is news or page's Alternate Layout is true, add 'full 'class on this div, otherwise add class 'half' -->
        <div :class="['col', page.alternate_layout || page.ItemID == $store.state.newsID ? 'full' : 'half']">
          <h1 v-if="page.ItemType == 'page.articles'">{{ page.name }}</h1>

          <span v-html="page.main_text"></span>

          <!-- Articles on news page -->
          <page-articles :page="page" v-if="page.ItemID == $store.state.newsID" />

          <!-- Faqs on faqs page -->
          <page-faqs :page="page" v-if="page.ItemID == $store.state.faqsID" />

          <!-- Profiles on team page -->
          <page-profiles :page="page" v-if="page.ItemID == $store.state.teamID" />

          <!-- Date and back link on articles page -->
          <div v-if="page.ItemType == 'page.articles'">
            <p class="published">Posted on {{ format(page.date_of_article, "DD MMM YY") }}</p>
            <!-- ??? -->
            <a class="back" href="/news">Back to news</a>
          </div>
        </div>

        <!-- If page's Alternate Layout is true, add 'full 'class on this div, otherwise add class 'half' -->
        <div :class="['col', page.alternate_layout ? 'full' : 'half']">
          <!-- Show downloads as needed -->
          <div class="downloads" v-if="page.downloads && page.downloads.length">
            <a href class="download" :key="download" v-for="download in page.downloads" @click.prevent="$set(download, '_showModal', true)">
              <img :src="download.image" />
              <span class="download-info">
                <span class="download-name">{{ download.name }}</span>
                <span class="download-desc">{{ download.description }}</span>
              </span>
            </a>
          </div>

          <!-- If page is not home and there are gallery images -->
          <page-gallery :page="page" :settings="settings" />

          <!-- If page is an article -->
          <img :alt="page.name" :src="page.image" class="articleimg" v-if="page.ItemType == 'page.articles'" />
        </div>
      </div>
    </section>

    <template v-for="download in page.downloads">
      <modal-download :key="download + 'modal'" :file="download.file" :title="download.name" :show="download._showModal" @close="$set(download, '_showModal', false)"></modal-download>
    </template>
  </div>
</template>

<script>
import HelpForm from "@/components/HelpForm.vue"
import HelpList from "@/components/HelpList.vue"
import PageGallery from "@/components/Gallery.vue"
import PageArticles from "@/components/Articles.vue"
import PageFaqs from "@/components/FAQs.vue"
import PageProfiles from "@/components/Profiles.vue"
import ModalDownload from "@/components/ModalDownload.vue"
import ModalVideo from "@/components/ModalVideo.vue"

import dateutil from "@/utils/formatdate"

export default {
  name: "InnerContent",
  props: ["page", "settings", "featured_pages"],
  mixins: [dateutil],
  data: function() {
    return {
      showVideoModal: false
    }
  },
  components: {
    HelpForm,
    HelpList,
    PageGallery,
    PageArticles,
    PageFaqs,
    PageProfiles,
    ModalDownload,
    ModalVideo
  }
}
</script>

<style scoped>
.content-holder {
  position: relative;
  z-index: 2;
}
.form-head {
  background: #0060a9;
  color: white;
  padding: 1.5rem 2rem;
  padding-bottom: 0.2rem;
}

.form-body {
  padding: 2rem;
  background: rgba(142, 159, 159, 0.205);
}

.txt {
  border: 0;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.pres {
  display: block;
  margin-bottom: 2rem;
  cursor: pointer;
}

textarea {
  width: 100%;
  height: 8rem;
}

button {
  border: 0;
  padding: 1rem 0.2rem;
  color: white;
  cursor: pointer;
  border-bottom: 5px solid #e2ba32;
  background: transparent;
  display: inline-block;
  margin-bottom: 3rem;
  padding-bottom: 1rem;
  color: #0060a9;
  font-family: "Frank Ruhl Libre", Georgia, "Times New Roman", Times, serif;
  font-size: 1.75rem;
}

button i {
  margin-right: 0.5rem;
}

.privacy {
  font-size: 1.3rem;
  color: #0060a9;
  float: right;
}

.privacy i {
  margin-right: 0.5rem;
}

a.back {
  text-decoration: underline;
}

.published {
  font-style: italic;
}

.articleimg {
  display: block;
  margin: auto;
}

.download {
  width: 100%;
  display: table;
  border: 3px solid #e2ba32;
  padding: 2rem;
  margin-bottom: 3rem;
  transition: all 0.2s;
}

.download:hover {
  border: 3px solid #0060a9;
}

.download img {
  float: left;
  width: 15%;
  margin-right: 4%;
  transition: all 0.2s;
}

.download:hover img {
  transform: scale(1.1) rotate(1deg);
}

.download-name {
  font-family: "Frank Ruhl Libre", Georgia, "Times New Roman", Times, serif;
  font-weight: bold;
  color: #0060a9;
  margin: 1rem 0;
  display: block;
}

.download-desc {
  line-height: 1.2;
  display: block;
}
</style>
