<template>
  <modal :show="show" @close="close">
    <div class="modal-header">
      <h3><i class="fa fa-info-circle"></i> Privacy notice</h3>
    </div>
    <div class="modal-body">
      <p>
        We will contact you in response to your enquiry, after which your information will be deleted from our systems. Your details will not be added to marketing lists, or passed to third parties.
        <a :href="$store.state.privacy.url" target="_blank" v-if="$store.state.privacy">Read more here</a>
      </p>
    </div>
    <div class="modal-footer text-right">
      <button @click="yes()" class="modal-default-button">Close</button>
    </div>
  </modal>
</template>

<script>
import Modal from "./Modal.vue"

export default {
  name: "ModalPrivacy",
  components: { Modal },
  props: ["show"],
  methods: {
    close: function() {
      this.$emit("close")
    },
    yes: function() {
      this.$emit("yes")
      this.close()
    }
  }
}
</script>

<style scoped>
.modal-header h3 {
  margin-top: 0;
  color: #0060a9;
}

.modal-header h3 i {
  color: #e2ba32;
  margin-right: 0.5rem;
}
.modal-body {
  margin: 20px 0;
  font-size: 1.5rem;
}

.modal-default-button {
  margin: 1rem 0.5rem;
  padding: 0.5rem 2rem;
  background-color: #f5f5f5;
  border: 0;
  cursor: pointer;
}

.modal-default-button:hover {
  background-color: #e1e1e1;
}

.modal-default-button:last-child {
  margin-right: 0;
}

.text-right {
  text-align: right;
}

a {
  text-decoration: underline;
}
</style>
