<template>
  <div class="articles">
    <div :key="article.ItemID" class="article" v-for="article in page.articles">
      <img :alt="article.name" :src="article.image" />
      <div>
        <h2>{{ article.name }}</h2>
        <p class="date">{{ format(article.date_of_article, "DD MMM YY") }}</p>
        <p>
          <span v-html="article.main_text.substring(0, 100) + '...'"></span>
          <router-link :to="'/' + article.url">Read more</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import formatdate from "@/utils/formatdate"

export default {
  name: "PageArticles",
  props: ["page"],
  mixins: [formatdate]
}
</script>

<style scoped>
.articles {
  margin-top: 5rem;
}

.article {
  float: left;
  width: 32.3%;
  padding: 2rem 2%;
  margin-right: 1%;
  display: table;
  margin-bottom: 2rem;
  background: rgba(143, 161, 161, 0.05);
}

.article:nth-child(even) {
  background: rgba(143, 161, 161, 0.15);
}

.article img {
  float: right;
  width: 20%;
}

.article > div {
  width: 75%;
}

.article a {
  display: block;
  text-decoration: underline;
  border-bottom: 0 !important;
}

h2 {
  font-family: "Frank Ruhl Libre", Georgia, "Times New Roman", Times, serif;
  font-size: 2rem;
  color: #0060a9;
}

.date {
  font-size: 1.5rem;
  color: #e2ba32;
}

@media screen and (max-width: 950px) {
  .article {
    width: 100%;
    margin-right: 0;
  }
}

@media screen and (max-width: 500px) {
  .article {
    padding: 3rem 5%;
  }

  .article img {
    width: 100%;
    float: none;
    margin-bottom: 2rem;
  }

  .article > div {
    width: 100%;
  }
}
</style>
