<template>
  <modal :show="show" @close="close" maxwidth="900px">
    <div class="modal-header">
      <h3><i class="fa fa-play-circle"></i> A brief introduction to GD Hall</h3>
    </div>
    <div class="modal-body">
      <div class="embed-container">
        <!--<iframe
          id="presentation"
          src="https://player.vimeo.com/video/352953292?byline=false&portrait=false&title=false"
          frameborder="0"
          webkitAllowFullScreen
          mozallowfullscreen
          allowfullscreen
        ></iframe>-->
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "./Modal.vue"

export default {
  name: "ModalPrivacy",
  components: { Modal },
  props: ["show"],
  methods: {
    close: function() {
      document.getElementById("presentation").src = document.getElementById("presentation").src
      this.$emit("close")
    },
    yes: function() {
      this.$emit("yes")
      this.close()
    }
  }
}
</script>

<style scoped>
.modal-header h3 {
  margin-top: 0;
  color: #0060a9;
  text-align: center;
}

.modal-header h3 i {
  color: #e2ba32;
  margin-right: 0.5rem;
}

.modal-body {
  font-size: 1.5rem;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
