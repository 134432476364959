<template>
  <section class="offices">
    <div class="inner">
      <div class="offices-container">
        <div :key="branch._id" class="office" v-for="branch in settings.branches">
          <h3>
            <i class="fa fa-map-marker"></i>
            {{ branch.name }}
          </h3>
          <h4>{{ branch.facilities }}</h4>
          <div class="opening" v-if="branch.opening_times">
            <i class="fa fa-clock-o"></i>
            {{ branch.opening_times }}
          </div>
          <a :href="'tel:' + removeSpaces(branch.telephone)" class="telephone" v-if="branch.telephone">
            <i class="fa fa-phone"></i>
            {{ branch.telephone }}
          </a>
          <a :href="'mailto:' + branch.email" class="email" v-if="branch.email">
            <i class="fa fa-envelope"></i>
            {{ branch.email }}
          </a>
          <!-- ??? -->
          <a :href="'https://www.google.com/maps/dir/' + encodeURI(branch.address)" target="_blank" rel="noreferrer" class="directions" v-if="branch.address">
            <i class="fa fa-location-arrow"></i> Directions
          </a>
        </div>
      </div>
      <p class="note">Visiting our Chapels of Rest is by appointment only</p>
    </div>
  </section>
</template>

<script>
import stringutil from "@/utils/formatstring"

export default {
  name: "PageOffices",
  props: ["settings"],
  mixins: [stringutil]
}
</script>

<style scoped>
.offices {
  background: url(../assets/tuxford.jpg);
  color: white;
  text-align: center;
  padding: 2rem 0;
}

.offices-container {
  width: 80%;
  display: table;
  margin: auto;
}

.office {
  background: #0060a9;
  border-radius: 0.7rem;
  width: 40%;
  float: left;
  margin: 4rem 5%;
  padding: 3rem 2.5%;
  text-align: left;
  font-size: 1.7rem;
}

.office a {
  display: block;
  margin-top: 1rem;
}

.office > i {
  font-size: 3rem;
}

i {
  opacity: 0.7;
  margin-right: 0.5rem;
  min-width: 2rem;
}

h3 {
  font-size: 2.5rem;
  padding-bottom: 1rem;
  font-family: "Frank Ruhl Libre", Georgia, "Times New Roman", Times, serif;
  border-bottom: 5px solid #e2ba32;
  display: inline-block;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
}

h4 {
  display: block;
  margin-bottom: 3rem;
}

.opening span {
  font-weight: bold;
  margin-left: 0.5rem;
}

.note {
  color: #333;
  font-size: 1.25rem;
  opacity: 0.5;
}

@media screen and (max-width: 950px) {
  .office {
    width: 100%;
    max-width: 600px;
  }

  .office:first-child {
    margin-bottom: 0;
  }

  h4 {
    display: block;
    clear: both;
  }
}
</style>
