<template>
  <section class="latest-obituaries">
    <div class="inner">
      <h3>Latest Obituaries</h3>
      <div class="container">
        <div :key="obit.ItemID" class="obit-thumb" v-for="obit in obits.slice(0, 3)">
          <router-link
            :title="obit.name"
            :style="{ backgroundImage: 'url(' + obit.image + ')' }"
            :to="{ name: 'obit', params: { itemid: obit.ItemID, name: urlFormat(obit.name) } }"
            class="obit-img"
          ></router-link>
          <div class="obit-info">
            <h4>{{ obit.name }}</h4>
            <h5>{{ obit.date_of_birth }} - {{ obit.date_of_death }}</h5>
            <router-link :to="{ name: 'obit', params: { itemid: obit.ItemID, name: urlFormat(obit.name) } }">view obituary</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import stringutil from "@/utils/formatstring"
export default {
  name: "LatestObituaries",
  props: ["obits"],
  mixins: [stringutil]
}
</script>

<style scoped>
.latest-obituaries {
  background: #0060a9;
  text-align: center;
  color: white;
  padding: 5rem 0;
}

h3 {
  font-family: "Frank Ruhl Libre", Georgia, "Times New Roman", Times, serif;
  font-size: 3rem;
  display: inline-block;
  padding-bottom: 1rem;
}

h3 i {
  margin-right: 0.5rem;
  font-size: 2.5rem;
}

.obit-img {
  border-top-left-radius: 0.7rem;
  border-bottom-left-radius: 0.7rem;
}
.obit-info {
  border-top-right-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
}

@media screen and (max-width: 1100px) {
  .obit-thumb {
    display: inline-block;
    float: none;
    width: 100%;
    max-width: 600px;
    margin: 0.5rem 0 !important;
  }
}
</style>
