<template>
  <div class="faqs">
    <template v-for="q in page.faqs">
      <h4 :key="'a' + q._id" class="accordion-toggle">{{ q.question }}</h4>
      <div :key="'q' + q._id" class="accordion-content" v-html="q.answer"></div>
    </template>
  </div>
</template>

<script>
export default {
  name: "PageFaqs",
  props: ["page"],
  created: function() {
    this.$nextTick().then(() => {
      window
        .$(".faqs")
        .find(".accordion-toggle")
        .click(function() {
          //Expand or collapse this panel
          window
            .$(this)
            .next()
            .slideToggle("fast")

          //Hide the other panels
          window
            .$(".accordion-content")
            .not(window.$(this).next())
            .slideUp("fast")
        })
    })
  }
}
</script>

<style scoped>
.accordion-toggle {
  cursor: pointer;
  font-family: "Frank Ruhl Libre", Georgia, "Times New Roman", Times, serif;
  color: #0060a9;
  margin-top: 2rem;
  border-left: 5px solid #e2ba32;
  padding-left: 2.5rem;
}
.accordion-content {
  display: none;
  padding: 1rem calc(2.5rem + 5px);
}
.accordion-content.default {
  display: block;
}
</style>
