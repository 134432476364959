<template>
  <div class="form inner">
    <div class="form-head">
      <h2>{{ settings.header }}</h2>
      <p>{{ settings.paragraph }}</p>
    </div>
    <div class="form-body">
      <div class="form-row">
        <input aria-label="Name" class="half txt" placeholder="Name *" type="text" v-model="form.name" />
        <input aria-label="Emai" class="half txt" placeholder="Email *" type="email" v-model="form.email" />
      </div>
      <div class="form-row">
        <input aria-label="Telephone" class="half txt" placeholder="Telephone" type="tel" v-model="form.tel" />
        <input aria-label="Preferred day and time" class="half txt" placeholder="Preferred day and time" type="text" v-model="form.preferred_date" />
      </div>
      <div class="form-row">
        <select aria-label="Preferred method of contact" class="half txt" v-model="form.preferred_contact">
          <option value>Preferred method of contact</option>
          <option>Phone</option>
          <option>Email</option>
          <option>Meeting</option>
        </select>
        <select aria-label="Subject of enquiry" class="half txt" v-model="form.subject">
          <option value>Subject of enquiry</option>
          <option>Arranging a Funeral</option>
          <option>Coffins</option>
          <option>Memorials</option>
          <option>How to register a death</option>
          <option>Dressing the Deceased</option>
          <option>Transport for the Funeral</option>
          <option>Visiting the Deceased</option>
          <option>Flowers</option>
          <option>Funeral Music and Stationery</option>
          <option>After the Funeral</option>
          <option>Funeral Costs and Payments</option>
          <option>Low Cost Funeral Option</option>
          <option>Pre-paid Funerals</option>
          <option>Other</option>
        </select>
      </div>
      <div class="form-row">
        <textarea aria-label="Message" class="txt" placeholder="Message *" v-model="form.message"></textarea>
      </div>
      <div class="form-row">
        <span class="success" v-show="emailsuccess">Thank you, your request has been sent and we'll be in touch soon.</span>
        <span class="error" v-show="emailerror">{{ errormessage }}</span>
        <button @click="sendEmail">Send message</button>
        <a @click.prevent="displayPrivacyModal()" class="privacy" href> <i class="fa fa-info-circle"></i> Privacy notice </a>
      </div>
      <vue-recaptcha @expired="onCaptchaExpired" @verify="onCaptchaVerified" ref="recaptcha" sitekey="6LePVAodAAAAACgShvL7DhIc9Jxovc2DT9rdIJxB" size="invisible"></vue-recaptcha>
    </div>
    <modal-privacy :show="showPrivacyModal" @close="showPrivacyModal = false"></modal-privacy>
  </div>
</template>

<script>
import api from "@/services/api"
import ModalPrivacy from "@/components/ModalPrivacy.vue"
import VueRecaptcha from "vue-recaptcha"

export default {
  name: "HelpForm",
  props: ["settings"],
  data: function() {
    return {
      showPrivacyModal: false,
      emailsuccess: false,
      emailerror: false,
      errormessage: "",
      form: {},
      initial_values: { preferred_contact: "", subject: "" }
    }
  },
  components: { ModalPrivacy, VueRecaptcha },
  created: function() {
    this.form = JSON.parse(JSON.stringify(this.initial_values))
  },
  methods: {
    displayPrivacyModal: function() {
      this.showPrivacyModal = true
    },
    sendEmail: function() {
      this.emailsuccess = false
      this.emailerror = false

      if (!this.form.name || !this.form.email || !this.form.message) {
        this.errormessage = "Please fill in all required fields, thanks!"
        this.emailerror = true
        return false
      }

      this.$refs.recaptcha.execute()
    },
    onCaptchaVerified: function(recaptchaToken) {
      const self = this
      self.status = "submitting"
      self.$refs.recaptcha.reset()

      var content = `The following message has been sent from the website:<br /><br />`
      if (this.form.subject) content += `Subject of enquiry: ${this.form.subject}<br />`
      if (this.form.preferred_contact) content += `Preferred method of contact: ${this.form.preferred_contact}<br />`
      if (this.form.preferred_date) content += `Preferred day and time: ${this.form.preferred_date}<br />`

      content += `Name: ${this.form.name}<br />
                  Email: ${this.form.email}<br />`

      if (this.form.tel) content += `Telephone: ${this.form.tel}<br />`
      if (this.form.message) content += `Message: ${this.form.message}<br />`

      api
        .verifyCaptcha(recaptchaToken)
        .then(res => {
          if (res.status == 200)
            api
              .sendEmail("Enquiry from the website", content)
              .then(() => {
                this.emailsuccess = true
                this.form = JSON.parse(JSON.stringify(this.initial_values))
              })
              .catch(() => {
                this.errormessage = "There has been an error submiting your message"
                this.emailerror = true
              })
        })
        .catch(() => {
          this.errormessage = "There has been an error submiting your message"
          this.emailerror = true
        })
    },
    onCaptchaExpired: function() {
      this.$refs.recaptcha.reset()
    }
  }
}
</script>

<style scoped>
.inner {
  max-width: 100rem;
}
.form-head {
  color: #0060a9;
  padding: 1.5rem 2rem;
  padding-bottom: 0.2rem;
  margin-top: 5rem;
  font-size: 1.8rem;
}

.form-body {
  padding: 2rem;
  display: table;
  width: 100%;
}
.form-row {
  display: table;
  width: 100%;
}

.txt {
  border: 0;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  font-size: 1.8rem;
  border: 1px solid #dbdbdb;
  border-radius: 0.7rem;
}

textarea.txt {
  width: 100%;
  height: 8rem;
}

button {
  border: 0;
  padding: 1rem 0.2rem;
  cursor: pointer;
  display: inline-block;
  padding: 1rem 2rem;
  color: #fff;
  border-radius: 0.7rem;

  background: #e2ba32;
  font-family: "Frank Ruhl Libre", Georgia, "Times New Roman", Times, serif;
  font-size: 1.75rem;
}

button i {
  margin-right: 0.5rem;
}

.privacy {
  font-size: 1.3rem;
  color: #0060a9;
  float: right;
}

.privacy i {
  margin-right: 0.5rem;
}

.error {
  display: block;
  color: #cc0000;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.success {
  display: block;
  color: green;
  font-size: 2rem;
  margin-bottom: 2rem;
}
</style>
