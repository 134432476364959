<template>
  <section class="banners" v-if="page.ItemID">
    <div class="cycle-slideshow" v-if="page.ItemID == $store.state.homeID">
      <div :key="banner._id" class="slide" v-for="banner in page.banners">
        <imgix :alt="banner.caption" :h="733" :src="banner.image" :w="1903" />
      </div>
      <div class="caption">
        <span>
          <span class="intro1">{{ page.intro_1 }}</span>
          <br />
          <span class="intro2">{{ page.intro_2 }}</span>
        </span>
      </div>
      <svg class="mask" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#ffffff"
          fill-opacity="1"
          d="M0,224L80,218.7C160,213,320,203,480,213.3C640,224,800,256,960,250.7C1120,245,1280,203,1360,181.3L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
        ></path>
      </svg>
    </div>
    <div class="cycle-slideshow" v-else>
      <div :key="banner._id" class="slide" v-for="banner in page.banners">
        <img :alt="banner.caption" :src="banner.image" />
        <div class="caption-inner">
          <span>{{ banner.caption }}</span>
        </div>
      </div>
      <svg class="mask" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#ffffff"
          fill-opacity="1"
          d="M0,224L80,218.7C160,213,320,203,480,213.3C640,224,800,256,960,250.7C1120,245,1280,203,1360,181.3L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
        ></path>
      </svg>
    </div>
  </section>
</template>

<script>
import api from "@/services/api"

export default {
  name: "PageBanners",
  props: ["page"],
  created: function() {},
  methods: {
    getAboutBanners: function() {
      api.getPageByItemID(this.$store.state.aboutID, "banners").then(about => this.setBanners(about.banners))
    },
    setBanners: function(banners) {
      this.$set(this.page, "banners", banners)
      this.initCycle()
    },
    initCycle: function() {
      this.$nextTick().then(() => {
        window.$(".cycle-slideshow").cycle({ slides: ".slide" })
      })
    }
  },
  watch: {
    page: function() {
      // if page object has ItemID (if loaded)
      // if not home page or about page (nowhere to inherit banners from)
      // if no banners object or banners has no length
      // then get banners from parent or about
      if (this.page.ItemID && this.page.ItemID !== this.$store.state.homeID && this.page.ItemID !== this.$store.state.aboutID && (!this.page.banners || !this.page.banners.length)) {
        if (this.page.ParentID) {
          api.getPageByItemID(this.page.ParentID, "banners").then(parent => {
            if (parent.banners && parent.banners.length) this.setBanners(parent.banners)
            else this.getAboutBanners()
          })
        } else {
          this.getAboutBanners()
        }
      } else if (this.page.ItemID) this.initCycle()
    }
  }
}
</script>

<style scoped>
.banners {
  position: relative;
  z-index: 1;
  line-height: 0;
  margin-bottom: -3rem;
}
.banners img {
  opacity: 1;
  filter: brightness(0.7);
}
.slide {
  position: relative;
}
.caption {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  font-family: "Frank Ruhl Libre", Georgia, "Times New Roman", Times, serif;
  font-size: 3rem;
  line-height: 1.5;
  z-index: 200;
  text-align: center;
}
.caption > span {
  position: relative;
  top: 35%;
  color: white;
}

.caption-inner {
  font-family: "Frank Ruhl Libre", Georgia, "Times New Roman", Times, serif;
  font-size: 3rem;
  position: absolute;
  padding: 0 5%;
  top: 45%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  line-height: 1;
  color: white;
  border-radius: 0.7rem;
  z-index: 160;
  text-align: center;
}

.mask {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 150;
  margin-bottom: -5rem;
}

.intro1 {
  font-size: 6rem;
  padding: 0 2rem;
  bottom: -0.3rem;
  position: relative;
}

.intro2 {
  font-size: 4rem;
  padding: 0.5rem 1.5rem;
}

.down {
  font-size: 7rem;
}

@media screen and (max-width: 1150px) {
  .caption > span {
    top: 28%;
  }
}

@media screen and (max-width: 950px) {
  .down {
    display: none;
  }

  .caption {
    text-align: left;
    width: 100%;
  }

  .caption > span {
    bottom: 20%;
    top: auto;
    position: absolute;
    padding: 1rem 2rem;
    width: 100%;
  }

  .intro1 {
    font-size: 3.5rem;
    padding: 1rem 2%;
    width: 100%;
  }

  .intro2 {
    font-size: 2.5rem;
    width: 100%;
    padding: 1rem 2%;
  }

  .mask {
    margin-bottom: -2rem;
  }
}

@media screen and (max-width: 650px) {
  .intro1 {
    font-size: 2.5rem;
  }

  .intro2 {
    font-size: 1.5rem;
    top: -1rem;
    position: relative;
  }

  .caption-inner {
    font-size: 2rem;
  }
  .banners {
    margin-bottom: 0;
  }
}
</style>
