<template>
  <section class="obituaries content">
    <div class="inner">
      <h1>
        Obituaries
      </h1>
      <div :key="'container' + page" class="container" v-for="page in pageCount" v-show="page == currentPage">
        <div :key="obit.ItemID" class="obit-thumb" v-for="obit in pageSlice(obits, pageSize, currentPage)">
          <router-link
            :title="obit.name"
            :style="{ backgroundImage: 'url(' + encodeURI(obit.image) + ')' }"
            :to="{ name: 'obit', params: { itemid: obit.ItemID, name: urlFormat(obit.name) } }"
            class="obit-img"
          ></router-link>
          <div class="obit-info">
            <h4>{{ obit.name }}</h4>
            <h5>{{ obit.date_of_birth }} - {{ obit.date_of_death }}</h5>
            <router-link :to="{ name: 'obit', params: { itemid: obit.ItemID, name: urlFormat(obit.name) } }">view obituary</router-link>
          </div>
        </div>
      </div>
      <div class="pages">
        <a :class="{ sel: page == currentPage }" :key="'selector' + page" @click.prevent="currentPage = page" href v-for="page in pageCount">{{ page }}</a>
      </div>
    </div>
  </section>
</template>

<script>
import pagination from "@/utils/pagination"
import stringutil from "@/utils/formatstring"

export default {
  name: "PageObituaries",
  props: ["obits"],
  mixins: [pagination, stringutil],
  data: function() {
    return {
      currentPage: 1,
      pageSize: 9
    }
  },
  computed: {
    pageCount: function() {
      return this.numberofpages(this.obits, this.pageSize)
    }
  }
}
</script>

<style scoped>
h1 i {
  margin-right: 0.5rem;
  font-size: 2.5rem;
}

.obit-thumb {
  background-color: rgba(141, 157, 158, 0.11);
  margin-top: 0;
  border-radius: 0.7rem;
}

.obit-img {
  border-top-left-radius: 0.7rem;
  border-bottom-left-radius: 0.7rem;
}

.obit-thumb:nth-child(2),
.obit-thumb:nth-child(5),
.obit-thumb:nth-child(8) {
  margin: 3rem 2%;
  margin-top: 0;
}

.pages {
  clear: both;
}

.pages a {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  background-color: rgba(141, 157, 158, 0.11);
  text-align: center;
  line-height: 2.9rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.7rem;
}

.pages a:hover {
  background-color: rgba(141, 157, 158, 0.25);
}

.pages a.sel {
  background-color: #e2ba32;
  color: white;
}

@media screen and (max-width: 1100px) {
  .obit-thumb {
    display: inline-block;
    float: none;
    width: 47.5%;
    margin: 0.5rem 0 !important;
    margin-right: 2.5% !important;
  }
}

@media screen and (max-width: 600px) {
  .obit-thumb {
    width: 100%;
    margin-right: 0 !important;
  }
}
</style>
