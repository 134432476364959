<template>
  <header>
    <div class="header">
      <div class="inner">
        <a class="logo" href="/">
          <img alt="GD Hal" src="../assets/gdhall.png" />
        </a>
        <div class="strapline">
          G. D Hall Ltd<br />
          Funeral Directors
        </div>
        <img class="reviews" src="../assets/google.png" alt="Google 5 Star Reviews" />

        <div class="telephones">
          <div :key="branch._id" class="office" v-for="branch in settings.branches">
            <div>
              <span class="location">{{ branch.name }}</span>
              <a :href="'tel:' + removeSpaces(branch.telephone)">{{ branch.telephone }}</a>
            </div>
          </div>
          <div class="office" v-if="settings && settings.mobile">
            <div>
              <span class="location">Mobile</span>
              <a :href="'tel:' + removeSpaces(settings.mobile)">{{ settings.mobile }}</a>
            </div>
          </div>
          <div class="att">24 Hour<br />Personal Attention</div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import stringutil from "@/utils/formatstring"

export default {
  name: "PageHeader",
  props: ["settings"],
  mixins: [stringutil]
}
</script>

<style scoped>
header {
  position: relative;
  z-index: 3;
  background: #0060a9;
}

.header {
  padding: 1rem 0;
}

.social {
  float: right;
  font-size: 3.5rem;
  padding-top: 3.1rem;
  color: #fff;
  margin-left: 1.5rem;
}

.social i {
  margin-left: 1.5rem;
}

.logo {
  width: 12rem;
  display: block;
  float: left;
  position: absolute;
  top: 1.5rem;
}

.location {
  font-weight: bold;
}

.strapline {
  font-family: "Frank Ruhl Libre", Georgia, "Times New Roman", Times, serif;
  float: left;
  font-size: 2rem;
  width: 40rem;
  margin-left: 0;
  padding-left: 2rem;
  color: #fff;
  position: relative;
  top: 2.5rem;
  font-weight: bold;
  display: none;
}

.telephones {
  float: right;
}

.att {
  display: inline-block;
  margin-left: 3rem;
  margin-top: 3rem;
  text-align: center;
  color: #fff;
  font-weight: bold;
  display: none;
}

.office {
  display: inline-block;
  margin-left: 3rem;
  margin-top: 3rem;
}

.office > i {
  display: inline-block;
  font-size: 3rem;
  margin-right: 1rem;
  color: #fff;
}

.office > div {
  display: inline-block;
}

.office > div > * {
  display: block;
  white-space: nowrap;
}

.office span {
  font-size: 1.3rem;
  color: #fff;
}

.office a {
  color: #e2ba32;
  font-size: 2rem;
}

.reviews {
  float: right;
  height: 5rem;
  margin-left: 5rem;
  margin-right: 2.5%;
  position: relative;
  bottom: -2.2rem;
}

@media screen and (max-width: 1600px) {
  .reviews {
    height: 4rem;
    margin-left: 3rem;
    bottom: -2.9rem;
  }
  .strapline {
    font-size: 1.8rem;
    width: 32rem;
  }
  .office {
    margin-top: 3.3rem;
  }
  .office a {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 1200px) {
  .reviews {
    height: 4rem;
    margin-left: 3rem;
    bottom: -2.9rem;
  }
}
@media screen and (max-width: 1060px) {
  .telephones {
    display: none;
  }
}

@media screen and (max-width: 950px) {
  .logo {
    position: relative;
    top: 0;
  }
}
@media screen and (max-width: 650px) {
  .social,
  .reviews {
    display: none;
  }

  .header {
    text-align: center;
  }

  .logo,
  .strapline {
    display: inline-block;
    float: none;
  }

  .strapline {
    top: -3.5rem;
    width: 35rem;
    border-left: 0;
    text-align: left;
  }
}

@media screen and (max-width: 500px) {
  .strapline {
    display: none;
  }
  .header {
    padding: 1.5rem;
  }
}
</style>
