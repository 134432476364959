<template>
  <div class="profiles">
    <div :key="profile._id" class="profile" v-for="profile in page.profiles">
      <img :alt="profile.name" :src="profile.image">
      <h3>{{profile.name}}</h3>
      <p v-if="profile.role">{{profile.role}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageProfiles",
  props: ["page"]
}
</script>

<style scoped>

.profiles {
  display:grid;
  grid-template-columns:1fr 1fr 1fr 1fr;
  grid-gap:2rem;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
.profile {
  display:block;
  width:23%;
  padding-right:2%;
  float:left;
}
}

h3 {
  padding-bottom: 0;
  margin-bottom: 0;
}

@media screen and (max-width: 950px) {
  .profiles {
  grid-template-columns:1fr 1fr 1fr;
  }
}

@media screen and (max-width: 650px) {
  .profiles {
  grid-template-columns:1fr 1fr;
  }
}
</style>
