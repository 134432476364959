<template>
  <div class="accreditations"><img src="../assets/logos.jpg" alt="Our accreditations" /></div>
</template>

<script>
export default {
  name: "PageAccreditations"
}
</script>

<style scoped>
.accreditations {
  text-align: center;
  margin: 4rem 2rem;
  opacity: 0.8;
}

img {
  width: 96%;
  max-width: 1000px;
  margin: auto;
  display: block;
}
</style>
