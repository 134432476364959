<template>
  <footer>
    <div class="footer">
      <div class="inner">
        <div class="strap">
          GD Hall is an independent, family owned Funeral Director caring for the people of North Nottinghamshire. Centred on the towns of Retford, Tuxford, and Ollerton and encompassing the
          surrounding areas of Bassetlaw and Newark &amp; Sherwood.
        </div>

        <div class="legal">
          &copy; {{ new Date().getFullYear() }} GD Hall | <router-link :to="'/' + $store.state.privacy.url" v-if="$store.state.privacy">{{ $store.state.privacy.name }}</router-link
          ><br />Website designed &amp; developed by
          <a href="https://chameleoncreative.marketing" rel="noreferrer" target="_blank">Chameleon Creative</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "PageFooter"
}
</script>

<style scoped>
.footer {
  background: #e2ba32;
  color: white;
  text-align: center;
  padding: 2rem;
  display: table;
  width: 100%;
  position: relative;
}

.legal {
  clear: both;
  padding-top: 2rem;
}

.strap {
  font-family: "Frank Ruhl Libre", Georgia, "Times New Roman", Times, serif;
  font-size: 2rem;
  opacity: 0.7;
  padding: 2rem;
  max-width: 115rem;
  margin: auto;
}

.sister {
  position: absolute;
  right: 3%;
  bottom: 0;
  background: #0060a9;
  color: white;
  padding: 10px 30px;
  display: inline-block;
  transition: all 0.2s;
}

.sister:hover {
  filter: brightness(0.95);
  padding-bottom: 20px;
}
@media screen and (max-width: 1070px) {
  .footer {
    padding-bottom: 7rem;
  }
  .sister {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 500px) {
  .sister {
    left: 10%;
    width: 80%;
    transform: none;
  }
}
</style>
