<template>
  <section class="content">
    <div class="inner">
      <div class="col half">
        <help-list :pages="pages"/>
      </div>
      <div class="col half gallery">
        <div class="view">
          <span>
            <i class="fa fa-image"></i> View gallery
          </span>
        </div>
        <a :class="index > 3 ? 'hide popup' : 'popup'" :href="image.image" :key="image._id" :title="image.caption" v-for="(image, index) in page.gallery">
          <imgix :alt="image.caption" :h="220" :src="image.image" :w="329" v-if="index < 4"/>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import HelpList from "@/components/HelpList.vue"

export default {
  name: "LinksGallery",
  props: ["page", "pages"],
  components: {
    HelpList
  }
}
</script>

<style scoped>
.help {
  list-style-type: none;
  padding: 0;
  margin: 0;
  float: left;
  width: 47%;
  padding-right: 3%;
}

.help li {
  margin-bottom: 1rem;
}
</style>
