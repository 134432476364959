<template>
  <div>
    <page-header :settings="settings" />
    <page-navigation :pages="main_pages" :settings="settings" />

    <!-- Show most things on everything except obituary list and individual obituary -->
    <page-banners :page="page" v-if="!isObit" />
    <inner-content :featured_pages="featured_pages" :page="page" :settings="settings" v-if="!isObit" />
    <latest-obituaries :obits="latest_obits" v-if="!isObit && page.ItemID !== $store.state.contactID" />

    <!-- Offices on contact only -->
    <page-offices :settings="settings" v-if="page.ItemID == $store.state.contactID" />

    <!-- form on all ex obit -->
    <help-form :settings="settings" v-if="!isObit" />

    <!-- Obituaries / individual obituary only -->
    <page-obituaries :obits="obits" v-if="page.ItemID == $store.state.obitID" />
    <page-obituary :page="page" v-if="page.ItemType == 'page.obituaries'" />

    <!-- Show accreditations on everything -->
    <page-accreditations />

    <page-footer />
  </div>
</template>

<script>
import api from "@/services/api"

import PageHeader from "@/components/Header.vue"
import PageNavigation from "@/components/Nav.vue"
import PageBanners from "@/components/Banners.vue"
import InnerContent from "@/components/InnerContent.vue"
import PageOffices from "@/components/Offices.vue"
import LatestObituaries from "@/components/LatestObituaries.vue"
import LinksGallery from "@/components/LinksGallery.vue"
import PageAccreditations from "@/components/Accreditations.vue"
import PageFooter from "@/components/Footer.vue"
import PageObituaries from "@/components/Obituaries.vue"
import PageObituary from "@/components/Obituary.vue"
import HelpForm from "@/components/HelpForm.vue"

export default {
  name: "page",
  components: {
    PageHeader,
    PageNavigation,
    PageBanners,
    PageOffices,
    LatestObituaries,
    LinksGallery,
    InnerContent,
    PageAccreditations,
    PageFooter,
    PageObituaries,
    PageObituary,
    HelpForm
  },
  data() {
    return {
      page: {},
      main_pages: [],
      obits: [],
      latest_obits: [],
      featured_pages: [],
      settings: {}
    }
  },
  metaInfo() {
    return {
      title: this.page.title || "GD Hall"
    }
  },
  computed: {
    isObit: function() {
      if (!this.page || !this.page.ItemID) return false
      var obitID = this.$store.state.obitID
      return this.page.ItemType == "page.obituaries" || this.page.ItemID == obitID
    }
  },
  created: function() {
    var route = this.$route
    var url = route.params.url
    var promises = []
    var store = this.$store
    var state = store.state

    if (route.name == "404") this.page = { main_text: "Sorry, this page cannot be found!" }

    if (route.name == "page-by-url" || route.name == "root")
      promises.push(
        api.getPageByURL(url || "default").then(page => {
          this.page = page

          if (!this.page || !this.page.ItemID) this.page = { main_text: "Sorry, this page cannot be found!" }
          // if (!this.page || !this.page.ItemID) this.$router.push("/404-not-found")
        })
      )

    if (route.name == "obit") {
      promises.push(
        api.getPageByItemID(route.params.itemid).then(obit => {
          if (!obit || !obit.ItemID) this.page = { main_text: "Sorry, this page cannot be found!" }
          obit.posts = []
          if (obit.candles && obit.candles.length) {
            var i = 0
            obit.candles.forEach(candle => {
              candle._type = "candle"
              candle._index = i
              i++
            })
            obit.posts = obit.posts.concat(obit.candles)
          }
          if (obit.messages && obit.messages.length) {
            obit.messages.forEach(message => {
              message._type = "message"
            })
            obit.posts = obit.posts.concat(obit.messages)
          }

          obit.posts.sort((a, b) => (a._timestamp > b._timestamp ? 1 : -1))
          return Promise.resolve(obit)
        })
      )
      promises.push(
        api.getPageByItemID(state.obitID).then(page => {
          if (!page || !page.ItemID) this.page = { main_text: "Sorry, this page cannot be found!" }
          return Promise.resolve(page)
        })
      )
    }

    promises.push(store.dispatch("getSettings").then(() => (this.settings = state.settings)))
    promises.push(
      store.dispatch("getMainPages").then(() => {
        this.main_pages = state.main_pages.filter(page => page.ItemID != state.privacyID)
        this.$nextTick().then(() => {
          // if (!window.$(".sf-menu .sf-arrows").length) window.$("ul.sf-menu").superfish()
        })
      })
    )
    promises.push(
      store.dispatch("getObits").then(() => {
        this.latest_obits = state.latest_obits
        this.obits = state.obits
      })
    )
    promises.push(store.dispatch("getFeaturedPages").then(() => (this.featured_pages = state.featured_pages)))
    store.dispatch("getPrivacyPage")

    Promise.all(promises).then(values => {
      if (route.name == "obit") {
        var fullpage = Object.assign(values[1], values[0])
        this.page = fullpage
        this.page.title = this.page.name
        this.page.meta_desc = this.page.date_of_birth + " to " + this.page.date_of_death
      }

      var subspromises = []

      if (route.name == "page-by-url" || route.name == "root") {
        subspromises.push(api.getSubPages("page.sub", this.page.ItemID).then(subs => this.$set(this.page, "subs", subs)))
        if (this.page.ItemID == state.newsID) subspromises.push(api.getSubPagesDesc("page.articles", this.page.ItemID).then(subs => this.$set(this.page, "articles", subs)))
      }

      Promise.all(subspromises).then(() => {
        this.$nextTick().then(() => {
          window.$(document).ready(function() {
            window.$(".popup").magnificPopup({
              type: "image",
              gallery: {
                enabled: true
              }
            })

            state.ready = true

            window.$(".article").matchHeight()
            window.$(".office").matchHeight()

            var magnificPopup = window.$.magnificPopup.instance

            /* Carrega a função quando clica no lightbox (senão não pega a classe utilizada) */
            window.$("a.popup").click(function() {
              /* Espera carregar o lightbox */
              setTimeout(function() {
                /* Swipe para a esquerda - Próximo */
                window.$(".mfp-container").swipe({
                  swipeLeft: function() {
                    magnificPopup.next()
                  },

                  /* Swipe para a direita - Anterior */
                  swipeRight: function() {
                    magnificPopup.prev()
                  }
                })
              }, 500)
            })

            // Select all links with hashes
            window
              .$('a[href*="#"]')
              // Remove links that don't actually link to anything
              .not('[href="#"]')
              .not('[href="#0"]')
              .click(function(event) {
                // On-page links
                if (location.pathname.replace(/^\//, "") == this.pathname.replace(/^\//, "") && location.hostname == this.hostname) {
                  // Figure out element to scroll to
                  var target = window.$(this.hash)
                  target = target.length ? target : window.$("[name=" + this.hash.slice(1) + "]")
                  // Does a scroll target exist?
                  if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault()
                    window.$("html, body").animate(
                      {
                        scrollTop: target.offset().top
                      },
                      1000,
                      function() {
                        // Callback after animation
                        // Must change focus!
                        var $target = window.$(target)
                        $target.focus()
                        if ($target.is(":focus")) {
                          // Checking if the target was focused
                          return false
                        } else {
                          $target.attr("tabindex", "-1") // Adding tabindex for elements not focusable
                          $target.focus() // Set focus again
                        }
                      }
                    )
                  }
                }
              })
          })
        })
      })
    })
  }
}
</script>
