<template>
  <div v-show="pages && pages.length">
    <h1>
      <i class="fa fa-check-square-o"></i> We can help with:
    </h1>
    <div>
      <ul :key="p" class="help" v-for="p in 2">
        <li :key="page.itemID" :to="page.url" v-for="page in pageSlice(pages, Math.ceil(pages.length/2), p)">
          <router-link :to="page.url">{{page.name}}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import pagination from "@/utils/pagination"

export default {
  name: "HelpList",
  props: ["pages"],
  mixins: [pagination]
}
</script>

<style scoped>
.help {
  list-style-type: none;
  padding: 0;
  margin: 0;
  float: left;
  width: 47%;
  padding-right: 3%;
}

.help li {
  margin-bottom: 1rem;
}
</style>
