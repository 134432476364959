<template>
  <section class="obituary content">
    <div class="inner">
      <div class="left">
        <div class="head">
          <img :alt="page.name" :src="page.image" />
          <div class="titles">
            <span class="in-memory">In memory of...</span>
            <span class="name">{{ page.name }}</span>
            <span class="dates">{{ page.date_of_birth }}</span>
          </div>
        </div>

        <div class="body" v-html="page.description"></div>

        <div class="actions">
          <a @click.prevent="displayMessageModal()" href> <i class="fa fa-commenting"></i> Post a message </a>
          <a @click.prevent="displayCandleModal()" href> <i class="fa fa-fire"></i> Light a candle </a>
        </div>

        <div class="posts">
          <div :class="{ candle: post._type == 'candle' }" :key="post.itemID" class="post" v-for="post in page.posts.filter(post => post.approved)">
            <img :src="$store.state.s3 + 'Candles/' + ((post._index % 3) + 1) + '.gif'" alt="Candle" v-if="post._type == 'candle'" />
            <p v-if="post._type == 'message'">
              {{ post.message }}
              <br />
              <br />
              <strong>{{ post.name }}</strong>
            </p>
            <p v-if="post._type == 'candle'">
              <strong>{{ post.name }}</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="share block">
          Share Obituary
          <a :href="'https://www.facebook.com/sharer/sharer.php?u=https%3A//www.gdhallfunerals.co.uk' + $route.fullPath" rel="noreferrer" target="_blank">
            <i class="fa fa-facebook"></i>
          </a>
          <a :href="'https://twitter.com/home?status=https%3A//www.gdhallfunerals.co.uk' + $route.fullPath" class="twitter-share-button" rel="noreferrer" target="_blank">
            <i class="fa fa-twitter"></i>
          </a>
        </div>

        <div class="funeralhead block" v-if="page.date_of_funeral">Funeral Details</div>
        <div class="funeralinfo block" v-if="page.date_of_funeral">
          <p v-if="page.date_of_funeral">
            <strong>Date:</strong>
            {{ page.date_of_funeral }}
          </p>
          <p v-if="page.time_of_funeral">
            <strong>Time:</strong>
            {{ page.time_of_funeral }}
          </p>
          <p v-if="page.location">
            <strong>Location:</strong>
            {{ page.location }}
          </p>
          <p v-if="page.wake">
            <strong>Wake:</strong>
            {{ page.wake }}
          </p>
        </div>
        <iframe
          v-if="page.location.length"
          :src="'https://www.google.com/maps/embed/v1/place?q=' + encodeURI(page.location.replace('&', '%26')) + '&key=AIzaSyBLzJ48bU1oX0FYz2Dpd1L2MSojJrZnF04'"
          allowfullscreen
          frameborder="0"
          height="300"
          style="border:0"
          width="100%"
        ></iframe>

        <div class="donatehead block" v-if="page.charity_url || page.donate_text">Donate</div>
        <div class="donateinfo block" v-if="page.charity_url || page.donate_text">
          <p v-if="page.donate_text">{{ page.donate_text }}</p>
          <p v-if="page.charity_url">
            <a :href="page.charity_url.indexOf('http') !== -1 ? page.charity_url : 'http://' + page.charity_url" rel="noreferrer" target="_blank">Click here to donate</a>
          </p>
        </div>
      </div>
    </div>
    <modal-message :obitid="page.ItemID" :obitname="page.name" :show="showMessageModal" @close="showMessageModal = false"></modal-message>
    <modal-candle :candlenumber="nextCandle" :obitid="page.ItemID" :obitname="page.name" :show="showCandleModal" @close="showCandleModal = false"></modal-candle>
  </section>
</template>

<script>
import dateutil from "@/utils/formatdate"
// import ordinal from "ordinal"

import ModalCandle from "@/components/ModalCandle.vue"
import ModalMessage from "@/components/ModalMessage.vue"

export default {
  name: "PageObituary",
  props: ["page"],
  mixins: [dateutil],
  data: function() {
    return {
      showMessageModal: false,
      showCandleModal: false
    }
  },
  components: { ModalCandle, ModalMessage },
  computed: {
    // funeralDate: function() {
    //   var d = ""
    //   if (this.page.date_of_funeral) {
    //     d = this.format(this.page.date_of_funeral, "dddd MMMM")
    //     d = d + " " + ordinal(this.format(this.page.date_of_funeral, "D")) + " "
    //     d = d + this.format(this.page.date_of_funeral, "YYYY")
    //   }
    //   return d
    // }
    nextCandle: function() {
      if (this.page.candles) {
        var candles = this.page.candles.filter(candle => candle.approved)
        if (candles.length) {
          var i = (candles[candles.length - 1]._index % 3) + 2
          if (i == 4) return (i = 1)
          return i
        } else return 1
      } else return 1
    }
  },
  methods: {
    displayMessageModal: function() {
      this.showMessageModal = true
    },
    displayCandleModal: function() {
      this.showCandleModal = true
    }
  }
}
</script>

<style scoped>
.left {
  width: 70%;
  float: left;
  margin-right: 4%;
}

.right {
  width: 26%;
  float: left;
  color: white;
  font-size: 2rem;
  font-family: "Frank Ruhl Libre", Georgia, "Times New Roman", Times, serif;
}

.block,
.donatehead {
  background: #0060a9;
  padding: 1rem 2rem;
}

.donatehead,
.funeralhead {
  border-top-left-radius: 0.7rem;
  border-top-right-radius: 0.7rem;
}

.funeralinfo,
.donateinfo {
  border-bottom-left-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
}

.share {
  margin-bottom: 2rem;
  border-radius: 0.7rem;
}

.share a {
  float: right;
  margin-left: 1rem;
  transition: all 0.2s;
}

.share a:hover {
  filter: brightness(1.1);
}

.share a i {
  display: block;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  background: #229ff1;
  position: relative;
}

.share a i.fa-facebook {
  background: #3d5a97;
}

.funeralhead {
  background: #e2ba32;
}

.funeralinfo,
.donateinfo {
  background: #f6f6f6;
  color: #666;
  font-size: 1.5rem;
  padding-top: 2rem;
  font-family: "Frank Ruhl Libre", Arial, Helvetica, sans-serif;
}

iframe {
  border-radius: 0.7rem;
  margin: 2rem 0;
}

.donateinfo a {
  font-weight: bold;
}

.head {
  background: #f6f6f6;
  display: table;
  width: 100%;
  border-radius: 0.7rem;
}

.head > img {
  float: left;
  width: 30%;
  border-top-left-radius: 0.7rem;
  border-bottom-left-radius: 0.7rem;
}

.titles {
  float: left;
  width: 70%;
  padding: 2rem 3rem;
  font-family: "Frank Ruhl Libre", Georgia, "Times New Roman", Times, serif;
}

.titles span {
  display: block;
}

.name {
  font-size: 4rem;
  line-height: 1.3;
  color: #333;
}

.dates {
  font-size: 2rem;
}

.body {
  padding: 3rem 0;
  padding-bottom: 1rem;
}

.actions {
  border-top: 5px solid #f6f6f6;
  padding: 2rem 0;
}

.actions a {
  display: inline-block;
  background: #f6f6f6;
  padding: 1rem 2rem;
  margin-right: 2rem;
  border-radius: 0.7rem;
}

.actions a i {
  margin-right: 1rem;
  color: #e2ba32;
}

.actions a:hover {
  background: rgb(233, 233, 233);
}

.posts {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  width: 100%;
  padding-bottom: 3rem;
}

.post {
  width: 100%;
  border: 1px solid rgb(233, 233, 233);
  padding: 2rem;
  padding-bottom: 0;
}

.post p {
  padding-bottom: 1rem;
}

@media screen and (max-width: 850px) {
  .left,
  .right {
    float: none;
    width: 100%;
    margin-right: 0;
  }
}

@media screen and (max-width: 550px) {
  .head > img {
    float: none;
    width: 100%;
    border-top-right-radius: 0.7rem;
    border-bottom-left-radius: 0;
  }

  .titles {
    width: 100%;
  }
}
</style>
