<template>
  <modal :show="show" @close="close">
    <div v-if="!success">
      <div class="modal-header">
        <h3><i class="fa fa-fire"></i> Light a candle</h3>
        <p class="note">Your candle will appear after being approved by the site administrator.</p>
        <img :src="$store.state.s3 + 'Candles/' + candlenumber + '.gif'" alt="Candle" />
      </div>
      <div class="modal-body">
        <input class="txt" placeholder="Your name" type="text" v-model="name" />
      </div>
      <div class="modal-footer text-right">
        <span class="error" v-show="error">{{ errormessage }}</span>
        <button @click="submit()" class="modal-default-button">Submit</button>
      </div>
    </div>
    <div v-else>
      <h3><i class="fa fa-fire"></i> Light a candle</h3>
      <p class="note">Thank you, we have received your post.<br /><br />Your candle will appear after being approved by the site administrator.</p>
      <div class="modal-footer text-right">
        <button @click="close" class="modal-default-button">Close</button>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "./Modal.vue"
import api from "@/services/api"

export default {
  name: "ModalCandle",
  data: function() {
    return {
      name: "",
      error: false,
      success: false,
      errormessage: ""
    }
  },
  components: { Modal },
  props: ["show", "obitid", "candlenumber", "obitname"],
  methods: {
    close: function() {
      this.$emit("close")
    },
    submit: function() {
      this.error = false

      if (!this.name) {
        this.error = true
        this.errormessage = "Please fill in all fields"
        return
      }

      var name = this.name
      var obitid = this.obitid
      var obitname = this.obitname
      api
        .obitLightCandle({
          name,
          obitid
        })
        .then(res => {
          if (res.status == 200) api.sendEmail("New candle lit", "A new candle has been lit on the obituary of " + obitname + ". Please log in to the CMS to check and approve it.")

          this.success = true
          this.name = ""
          this.error = false
        })
        .catch(() => {
          this.error = true
          this.errormessage = "There has been a problem submitting your message"
        })
    }
  }
}
</script>

<style scoped>
.note {
  font-size: 1.2rem;
}

.modal-header h3 {
  margin-top: 0;
  color: #0060a9;
}

.modal-header h3 i {
  color: #e2ba32;
  margin-right: 0.5rem;
}

.txt {
  width: 100%;
  padding: 0.5rem 1.5rem;
  margin-top: 1rem;
  border: 1px solid #d5d5d5;
}

textarea {
  height: 10rem;
}

.modal-body {
  margin: 20px 0;
  font-size: 1.5rem;
}

.modal-default-button {
  margin: 1rem 0.5rem;
  padding: 0.5rem 2rem;
  background-color: #f5f5f5;
  border: 0;
  cursor: pointer;
}

.modal-default-button:hover {
  background-color: #e1e1e1;
}

.modal-default-button:last-child {
  margin-right: 0;
}

.text-right {
  text-align: right;
}

.form-label {
  display: block;
  margin-bottom: 1em;
}

.form-label > .form-control {
  margin-top: 0.5em;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5em 1em;
  line-height: 1.5;
  border: 1px solid #ddd;
}

.error {
  display: block;
  padding: 1rem 0;
  color: rgb(163, 1, 1);
}
</style>
