<template>
  <modal :show="show" @close="close">
    <div class="modal-header">
      <h3><i class="fa fa-cloud-download"></i> Download your file</h3>
    </div>
    <div v-if="!success" class="modal-body">
      <div>
        <input class="txt" placeholder="Your name" type="text" v-model="name" />
        <input class="txt" placeholder="Your email" type="email" v-model="email" />
      </div>
    </div>
    <div v-if="!success" class="modal-footer text-right">
      <span class="error" v-show="error">{{ errormessage }}</span>
      <button @click="submit()" class="modal-default-button">Submit</button>
    </div>
    <div v-if="success" class="thanks">
      <p><br />Thank you</p>
      <p>
        <a :href="file" target="_blank" rel="noreferrer">Click here to download your file</a>
      </p>
    </div>
  </modal>
</template>

<script>
import Modal from "./Modal.vue"
import api from "@/services/api"

export default {
  name: "ModalMessage",
  data: function() {
    return {
      name: "",
      email: "",
      error: false,
      errormessage: "",
      success: false
    }
  },
  components: { Modal },
  props: ["show", "file", "title"],
  methods: {
    close: function() {
      this.$emit("close")
    },
    submit: function() {
      this.error = false

      if (!this.name || !this.email) {
        this.error = true
        this.errormessage = "Please fill in all fields"
        return
      }

      var name = this.name
      var email = this.email
      var title = this.title

      var content = `The following person has download a file on the website:<br /><br />`

      content += `Name: ${name}<br />
                  Email: ${email}<br />
                  File: ${title}<br />`

      api
        .sendEmail("Download on the website", content)
        .then(() => {
          this.success = true
        })
        .catch(() => {
          this.errormessage = "There has been an error submiting your message"
          this.emailerror = true
        })
    }
  }
}
</script>

<style scoped>
.note {
  font-size: 1.2rem;
}
.modal-header h3 {
  margin-top: 0;
  color: #0060a9;
}

.modal-header h3 i {
  color: #e2ba32;
  margin-right: 0.5rem;
}

.txt {
  width: 100%;
  padding: 0.5rem 1.5rem;
  margin-top: 1rem;
  border: 1px solid #d5d5d5;
}

textarea {
  height: 10rem;
}

.modal-body {
  margin: 20px 0;
  font-size: 1.5rem;
}

.modal-default-button {
  margin: 1rem 0.5rem;
  padding: 0.5rem 2rem;
  background-color: #f5f5f5;
  border: 0;
  cursor: pointer;
}

.modal-default-button:hover {
  background-color: #e1e1e1;
}

.modal-default-button:last-child {
  margin-right: 0;
}

.text-right {
  text-align: right;
}

.form-label {
  display: block;
  margin-bottom: 1em;
}

.form-label > .form-control {
  margin-top: 0.5em;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5em 1em;
  line-height: 1.5;
  border: 1px solid #ddd;
}

.error {
  display: block;
  padding: 1rem 0;
  color: rgb(163, 1, 1);
}

a {
  font-weight: bold;
  border-bottom: 2px solid #e2ba32;
}
</style>
