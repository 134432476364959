<template>
  <nav>
    <div class="inner">
      <ul class="sf-menu">
        <template v-for="page in pages">
          <li v-if="!page.hide" :key="page.ItemID">
            <router-link :to="page.url == 'default' ? '/' : '/' + page.url">
              {{ page.name }}
              <i class="fa fa-caret-down" v-if="page.subs && page.subs.length && page.ItemID !== 'niBmKeAiw'" />
            </router-link>
            <ul v-if="page.subs && page.subs.length && page.ItemID !== 'niBmKeAiw'">
              <li>
                <router-link :to="page.url == 'default' ? '/' : '/' + page.url">{{ page.name }}</router-link>
              </li>
              <li :key="sub.ItemID" v-for="sub in page.subs">
                <router-link :to="'/' + sub.url">{{ sub.name }}</router-link>
              </li>
            </ul>
          </li>
        </template>
        <li v-if="settings.price_list"><a :href="settings.price_list" target="_blank" rel="noreferrer">Standardised Price List</a></li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "PageNavigation",
  props: ["pages", "settings"],
  created: function() {}
}
</script>

<style scoped>
nav {
  background: #0060a9;
  color: white;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  margin: 0;
}

nav i {
  margin-left: 0.5rem !important;
  opacity: 0.5;
}

/*** SF MENU ***/
.sf-menu {
  width: 85%;
  float: right;
  display: block;
}
.sf-menu,
.sf-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sf-menu li {
  position: relative;
}
.sf-menu ul {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  z-index: 99;
  text-align: left;
}
.sf-menu > li {
  float: left;
}
.sf-menu li:hover > ul,
.sf-menu li.sfHover > ul {
  display: block;
}

.sf-menu a {
  display: block;
  position: relative;
}
.sf-menu ul ul {
  top: 0;
  left: 100%;
}

/*** DEMO SKIN ***/
.sf-menu {
  text-align: center;
  font-size: 1.7rem;
  padding-top: 1.5rem;
}
.sf-menu ul {
  min-width: 12em; /* allow long menu items to determine submenu width */
  *width: 12em; /* no auto sub width for IE7, see white-space comment below */
}
.sf-menu a {
  padding: 0.75em 1.5em;
  text-decoration: none;
  zoom: 1; /* IE7 */
}

.sf-menu li {
  white-space: nowrap; /* no need for Supersubs plugin */
  *white-space: normal; /* ...unless you support IE7 (let it wrap) */
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
}

.sf-menu li ul li {
  width: 100%;
}
.sf-menu li ul li a {
  padding: 1.5rem 1em;
}

.sf-menu > li > a {
  display: block;
}

.sf-menu ul li a {
  background: #e2ba32;
}
.sf-menu ul ul li {
  background: rgba(255, 255, 255, 0.1);
}
.sf-menu li:hover a,
.sf-menu li.sfHover a,
.sf-menu a.router-link-exact-active {
  background: #e2ba32;
  /* only transition out, not in */
  -webkit-transition: none;
  transition: none;
}
.sf-menu > li > a {
  border-top-right-radius: 0.7rem;
  border-top-left-radius: 0.7rem;
}
.sf-menu ul > li:last-child a {
  border-bottom-right-radius: 0.7rem;
  border-bottom-left-radius: 0.7rem;
}
.sf-menu li ul li:hover a {
  background: #f3ca43;
}

/*** arrows (for all except IE7) **/
.sf-arrows .sf-with-ul {
  padding-right: 2.5em;
  *padding-right: 1em; /* no CSS arrows for IE7 (lack pseudo-elements) */
}
/* styling for both css and generated arrows */
.sf-arrows .sf-with-ul:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 1em;
  margin-top: -3px;
  height: 0;
  width: 0;
  /* order of following 3 rules important for fallbacks to work */
  border: 5px solid transparent;
  border-top-color: #dfeeff; /* edit this to suit design (no rgba in IE8) */
  border-top-color: rgba(255, 255, 255, 0.5);
}
.sf-arrows > li > .sf-with-ul:focus:after,
.sf-arrows > li:hover > .sf-with-ul:after,
.sf-arrows > .sfHover > .sf-with-ul:after {
  border-top-color: white; /* IE8 fallback colour */
}
/* styling for right-facing arrows */
.sf-arrows ul .sf-with-ul:after {
  margin-top: -5px;
  margin-right: -3px;
  border-color: transparent;
  border-left-color: #dfeeff; /* edit this to suit design (no rgba in IE8) */
  border-left-color: rgba(255, 255, 255, 0.5);
}
.sf-arrows ul li > .sf-with-ul:focus:after,
.sf-arrows ul li:hover > .sf-with-ul:after,
.sf-arrows ul .sfHover > .sf-with-ul:after {
  border-left-color: white;
}

@media screen and (max-width: 1100px) {
  .sf-menu a {
    padding: 0.75em 1em;
  }
}
@media screen and (max-width: 950px) {
  nav {
    display: none;
  }
}
</style>
