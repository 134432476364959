<template>
  <div>
    <div class="gallery">
      <!-- On keepsakes page, link to subs, not image -->
      <template v-if="page.ItemID != $store.state.keepsakesID">
        <a
          :class="[index > 3 && !page.alternate_layout ? 'hide popup' : 'popup', page.gallery.length == 1 ? 'full' : '']"
          :href="image.image"
          :key="image._id"
          :title="image.caption"
          v-for="(image, index) in page.gallery"
        >
          <imgix :alt="image.caption" :h="page.gallery.length == 1 ? 406 : 220" :src="image.image" :w="page.gallery.length == 1 ? 658 : 329" v-if="index < 4 || page.alternate_layout == true" />
        </a>
        <div class="view" v-if="page.gallery && page.gallery.length > 1 && !page.alternate_layout">
          <span>
            View gallery
          </span>
        </div>
      </template>
      <template v-else>
        <router-link :key="sub.itemID" :to="sub.url" class="thumb" v-for="sub in page.subs">
          <img :alt="sub.name" :src="sub.image" />
          <span class="thumb-span">{{ sub.name }}</span>
        </router-link>
      </template>
    </div>

    <div class="fees" v-if="settings.price_list && page.ItemID == $store.state.homeID">
      <div class="fees-list">
        <a :href="settings.price_list" class="fees-download" v-if="settings.price_list" rel="noreferrer" target="_blank">
          <i class="fa fa-file-pdf-o"></i> <span class="fees-name">Standardised Price List</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageGallery",
  props: ["page", "settings"]
}
</script>

<style scoped></style>
